import { useState, useCallback } from 'react';
import { Button, tx } from '@hipagesgroup/toolbox';
import { ChevronRightSmall } from '@hipagesgroup/icons';
import { getThumborURL } from '@hipagesgroup/utils';
import { BannerText } from '~/components/layout';
import { GetQuoteLocationSelect } from '~/components/layout/GetQuoteLocationSelect';
import { useSearchParams } from '@remix-run/react';
import banner from '~/assets/banner-painter.jpg';
import iconProblem from '~/assets/icons/icon-problem.png';
import iconCompete from '~/assets/icons/icon-compete.png';
import iconBestTradie from '~/assets/icons/icon-best-tradie.png';
import { useGetQuotesPackageWrapperContext } from '~/components/providers/GetQuotesPackageWrapper';
import { useSetupSeoMetrics } from '~/utils/snowplow';

export default function Page() {
  const [searchParams] = useSearchParams();
  const customJobTitle = searchParams.get('jt');
  const bannerTexts =
    typeof customJobTitle === 'string' ? [customJobTitle] : ['tradies', 'plumbers'];
  const [userTextInput, setUserTextInput] = useState<string>('');
  const { openGetQuotesWithPrefilledCategory } = useGetQuotesPackageWrapperContext();
  const openGetQuotesWithLocation = useCallback(
    (location?: string) => {
      openGetQuotesWithPrefilledCategory(location);
    },
    [openGetQuotesWithPrefilledCategory]
  );

  const desktopH1 = `Get up to 3 quotes from trusted local ${customJobTitle ? customJobTitle : 'tradies or home service providers'}`;
  useSetupSeoMetrics({
    h1: desktopH1,
    title: 'Get Quote (Up To 3 Providers) - Fast, Free & No Obligations',
    siteSection: 'Homepage',
  });

  return (
    <>
      {/* Banner */}
      <section
        style={{ backgroundImage: `url(${getThumborURL(banner)})` }}
        className={tx(
          'py-ml md:py-xl text-[#FFFFFF] relative',
          'bg-gradient-to-b bg-[#3E8BDE] from-[#183C8B] to-[#275CE0]', // Mobile BG
          'max-sm:!bg-none md:from-transparent md:to-transparent md:bg-cover md:bg-center' // Desktop BG
        )}
      >
        <div className="max-w-[1008px] mx-auto px-md md:grid md:grid-cols-12 md:gap-lg">
          <div className="md:col-span-8 lg:col-span-9">
            <div className="text-center mb-md md:text-left md:mb-lg">
              <h1 className="text-display-sm md:text-title-lg md:mb-md">
                {/* Desktop */}
                <span className="hidden md:block">{desktopH1}</span>
                {/* Mobile */}
                <span className="block md:hidden">
                  Up to 3 quotes from
                  <br />
                  <BannerText items={bannerTexts} /> in
                </span>
              </h1>

              <div className="hidden md:block">
                <p className="text-body mb-md">
                  Post your job and we&apos;ll notify local providers from our network of over
                  33,000 tradies to contact you about quotes, job details, and more to help with
                  your home improvement needs.
                </p>
                <p className="text-body-emphasis">
                  Our platform is fast and free, with no obligation to hire.
                </p>
              </div>
            </div>

            <div className="block md:hidden mb-md bg-surface rounded-md">
              <GetQuoteLocationSelect
                placeholder="Enter postcode (e.g. 2000)"
                userTextInput={userTextInput}
                setUserTextInput={setUserTextInput}
                onValueSelect={openGetQuotesWithLocation}
              />
            </div>

            <Button
              type="button"
              onClick={() => openGetQuotesWithLocation(userTextInput)}
              className="w-full md:w-auto"
            >
              Get Quotes Now
            </Button>
          </div>
        </div>
      </section>

      {/* Quote process section */}
      <section className="py-lg text-content relative bg-surface">
        <div className="max-w-[1008px] mx-auto px-md grid grid-cols-1 grid-flow-row md:grid-cols-[1fr_min-content_1fr_min-content_1fr] items-center gap-md">
          <div className="text-body text-content flex items-center md:justify-center gap-md">
            <img src={iconProblem} alt="Tell us your problem" />
            <div className="md:max-w-[150px]">Tell us your problem</div>
          </div>
          <div className="hidden md:block text-content-muted">
            <ChevronRightSmall />
          </div>
          <div className="text-body text-content flex items-center md:justify-center gap-md">
            <img src={iconCompete} alt="Tradies compete for your business" />
            <div className="md:max-w-[150px]">Tradies compete for your business</div>
          </div>
          <div className="hidden md:block text-content-muted">
            <ChevronRightSmall />
          </div>
          <div className="text-body text-content flex items-center md:justify-center gap-md">
            <img src={iconBestTradie} alt="You choose the best tradie for the job" />
            <div className="md:max-w-[150px]">You choose the best tradie for the job</div>
          </div>
        </div>
      </section>
    </>
  );
}
